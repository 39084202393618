import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Title1, Title2, Title3, Title4, Title5 } from './lib/index.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "titles"
    }}>{`Titles`}</h1>
    <p>{`Six levels of Titles`}</p>
    <Playground __position={0} __code={'<Title1>Magna labore adipisicing</Title1>\n<Title2>Eiusmod elit tempor incididunt mollit quis nostrud.</Title2>\n<Title3>\n  Ipsum adipisicing consectetur eiusmod voluptate sunt qui labore\n</Title3>\n<Title4>Non laboris mollit do laborum velit</Title4>\n<Title5>Laborum cillum dolor eu veniam est</Title5>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Title1,
      Title2,
      Title3,
      Title4,
      Title5,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Title1 mdxType="Title1">Magna labore adipisicing</Title1>
    <Title2 mdxType="Title2">Eiusmod elit tempor incididunt mollit quis nostrud.</Title2>
    <Title3 mdxType="Title3">Ipsum adipisicing consectetur eiusmod voluptate sunt qui labore</Title3>
    <Title4 mdxType="Title4">Non laboris mollit do laborum velit</Title4>
    <Title5 mdxType="Title5">Laborum cillum dolor eu veniam est</Title5>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      